import React from 'react';

class Dates extends React.Component {
    render(){
        return (
            <div className = "datesContent">
                <div className="pageTitle"><h2>Litter Pick Dates</h2></div>
                <div className="pageContent">
                    <p>The following dates have been scheduled for 2025.</p>
                    <p>All dates are subject to change at short notice - adverse weather may require last minute changes or cancellations.</p>
                    <p>Please check before attending. Updates will be sent to the email list and posted on social media.</p>
                    <br /><br/>
                    <div className="desktop">
                        <table aria-label="Event dates">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Date</th>
                                    <th>Start</th>
                                    <th>End</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>25th January</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>22nd February</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>29th March</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr className="next">
                                    <td>Saturday</td>
                                    <td>26th April</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>17th May</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>28th June</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>26th July</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>30th August</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>20th September</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>25th October</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>29th November</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>13th December</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>31st January 2026</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                    <div className="mobile">
                        <p className="dateLine past">
                            Saturday 25th January
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine past">
                            Saturday 22nd February
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine past">
                            Saturday 29th March
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine next">
                            Saturday 26th April
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 17th May
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 28th June
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 26th July
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 30h August
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 20th September
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 25th October
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 29th November
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 13th December
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                        <p className="dateLine">
                            Saturday 31st January 2026
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dates;