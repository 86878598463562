import React from 'react';

class Stats extends React.Component {
    render(){
        return (
            <div className = "statsContent">
                <div className="pageTitle"><h2>Crew Statistics</h2></div>
                <div className="pageContent">
                    <p>We will update this after each event to record what the crew is finding. Updates are posted to the social media accounts after events so keep your eyes open for any unusual finds!</p>
                    <br/>
                    <br/>
                    <table title="Statistics" id="stats">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Crew</th>
                                <th>No. Bags</th>
                                <th>Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>29/03/2025</td>
                            <td>8</td>
                            <td>7</td>
                            <td>4.16kg</td>
                        </tr>
                        <tr>
                            <td>22/02/2025</td>
                            <td>8</td>
                            <td>10</td>
                            <td>9kg</td>
                        </tr>
                        <tr>
                            <td>25/01/2025</td>
                            <td>9</td>
                            <td>9</td>
                            <td>17.97kg</td>
                        </tr>
                        <tr>
                            <td>14/12/2024</td>
                            <td>10</td>
                            <td>10</td>
                            <td>12.73kg</td>
                        </tr>
                        <tr></tr>
                        <tr>
                            <td>28/09/2024</td>
                            <td>9</td>
                            <td>8</td>
                            <td>11.92kg</td>
                        </tr>
                        <tr>
                            <td>20/07/2024</td>
                            <td>10</td>
                            <td>11</td>
                            <td>8.4kg</td>
                        </tr>
                        <tr>
                            <td>29/06/2024</td>
                            <td>8</td>
                            <td>8</td>
                            <td>6.64kg</td>
                        </tr>
                        <tr>
                            <td>18/05/2024</td>
                            <td>8</td>
                            <td>8</td>
                            <td>4.75kg</td>
                        </tr>
                        <tr>
                            <td>27/04/2024</td>
                            <td>9</td>
                            <td>10</td>
                            <td>12.71kg</td>
                        </tr>
                        <tr>
                                <td>30/03/2024</td>
                                <td>7</td>
                                <td>8</td>
                                <td>13.97kg</td>
                        </tr>
                        <tr>
                                <td>27/01/2024</td>
                                <td>21</td>
                                <td>19</td>
                                <td>38.18kg</td>
                        </tr>
                        <tr>
                                <td>16/12/2023</td>
                                <td>8</td>
                                <td>10</td>
                                <td>21.72kg</td>
                        </tr>
                        <tr>
                                <td>25/11/2023</td>
                                <td>4</td>
                                <td>4</td>
                                <td>6.64kg</td>
                        </tr>
                        <tr>
                                <td>30/09/2023</td>
                                <td>15</td>
                                <td>13</td>
                                <td>12.06kg</td>
                        </tr>
                        <tr>
                                <td>26/08/2023</td>
                                <td>12</td>
                                <td>12</td>
                                <td>16.49kg</td>
                        </tr>
                        <tr>
                                <td>22/07/2023</td>
                                <td>7</td>
                                <td>2</td>
                                <td>2.17kg</td>
                        </tr>
                        <tr>
                                <td>24/06/2023</td>
                                <td>14</td>
                                <td>-</td>
                                <td>6.99kg</td>
                        </tr>
                        <tr>
                                <td>20/5/2023</td>
                                <td>20</td>
                                <td>-</td>
                                <td>8.42kg</td>
                        </tr>
                        <tr>
                                <td>29/04/2023</td>
                                <td>13</td>
                                <td>10</td>
                                <td>7.98kg</td>
                        </tr>
                        <tr>
                                <td>25/03/2023</td>
                                <td>25</td>
                                <td>22</td>
                                <td>20.61kg</td>
                        </tr>
                        <tr>
                                <td>25/02/23</td>
                                <td>18</td>
                                <td>16</td>
                                <td>10.71kg</td>
                        </tr>
                        <tr>
                                <td>28/01/23</td>
                                <td>16</td>
                                <td>14</td>
                                <td>49.76kg</td>
                        </tr>
                        <tr>
                                <td>17/12/22</td>
                                <td>9</td>
                                <td>7</td>
                                <td>8.48kg</td>
                        </tr>
                        <tr>
                                <td>26/11/22</td>
                                <td>6</td>
                                <td>7</td>
                                <td>7.59kg</td>
                        </tr>
                        <tr>
                                <td>29/10/22</td>
                                <td>8</td>
                                <td>9</td>
                                <td>16.25kg</td>
                        </tr>
                        <tr>
                                <td>24/09/22</td>
                                <td>4</td>
                                <td>4</td>
                                <td>5.89kg</td>
                        </tr>
                        <tr>
                                <td>27/08/22</td>
                                <td>12</td>
                                <td>10</td>
                                <td>10.66kg</td>
                        </tr>
                        <tr>
                                <td>24/07/22</td>
                                <td>9</td>
                                <td>8</td>
                                <td>6.36kg</td>
                            </tr>
                        <tr>
                                <td>25/06/22</td>
                                <td>8</td>
                                <td>9</td>
                                <td>9.54kg</td>
                            </tr>
                            <tr>
                                <td>21/05/22</td>
                                <td>18</td>
                                <td>19</td>
                                <td>16.25kg</td>
                            </tr>
                            <tr>
                                <td>23/04/22</td>
                                <td>20</td>
                                <td>14</td>
                                <td>16.69kg</td>
                            </tr>
                            <tr>
                                <td>26/03/22</td>
                                <td>8</td>
                                <td>8</td>
                                <td>7.69kg</td>
                            </tr>
                            <tr>
                                <td>26/02/22</td>
                                <td>14</td>
                                <td>12</td>
                                <td>21.95kg</td>
                            </tr>
                            <tr>
                                <td>29/01/22</td>
                                <td>34</td>
                                <td>18</td>
                                <td>27.72kg</td>
                            </tr>
                            <tr>
                                <td>11/12/21</td>
                                <td>5</td>
                                <td></td>
                                <td>10.83kg</td>
                            </tr>
                            <tr>
                                <td>13/11/21</td>
                                <td></td>
                                <td></td>
                                <td>4.7kg</td>
                            </tr>
                            <tr>
                                <td>09/10/21</td>
                                <td>6</td>
                                <td>7</td>
                                <td>12.93kg</td>
                            </tr>
                            <tr>
                                <td>12/06/21</td>
                                <td>2</td>
                                <td></td>
                                <td>4.13kg</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default Stats;